import { Box, Drawer, Link, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 150,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  avatar: {
    height: 64,
  },
  content: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  link: {
    color: 'inherit',
  },
}));

const items = [
  {
    label: 'Email',
    icon: EmailIcon,
    href: 'mailto:lonergan.kristian@gmail.com',
  },
  {
    label: 'LinkedIn',
    icon: LinkedInIcon,
    href: 'https://www.linkedin.com/in/kristianlonergan/',
  },
  {
    label: 'Github',
    icon: GitHubIcon,
    href: 'https://github.com/KristianLonergan',
  },
];

const MenuDrawer = ({ className, isOpen, onClose, ...rest }) => {
  const classes = useStyles();

  const linkProps = {
    component: Link,
    target: '_blank',
    rel: 'noreferrer',
    className: classes.link,
  };

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={onClose}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box p={3} className={classes.content}>
        <div className={classes.container}>
          <img src="static/ava.png" alt="avatar" className={classes.avatar} />
        </div>

        <List disablePadding>
          {items.map(({ label, href, icon: Icon }) => (
            <ListItem key={label} {...linkProps} href={href}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

MenuDrawer.defaultProps = {
  isOpen: false,
};

export default MenuDrawer;
