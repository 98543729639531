import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';

const routes = [
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/home')),
      },
      {
        exact: true,
        path: '/playground/forms',
        component: lazy(() => import('src/views/playground')),
      },
      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView')),
      },
      {
        exact: true,
        path: '/sight-maintenance',
        component: lazy(() => import('src/views/SightMaintenance')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export default routes;
