import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import routes, { renderRoutes } from 'src/routes';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { useEffect } from 'react';

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  useEffect(
    () =>
      console.log(
        '%cWelcome to my website! If you find any bugs let me know :)',
        `
          background: #8a85ff;
          border: 3px solid #1c2025;
          color: white;
          font-size: 20px;
          margin: 10px;
          padding: 10px;
        `
      ),
    []
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <GlobalStyles />
          {renderRoutes(routes)}
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
