import _ from 'lodash';
import {
  colors,
  responsiveFontSizes,
  createTheme as createMuiTheme,
} from '@mui/material';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

const baseOptions = {
  direction: 'ltr',
  typography,
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    palette: {
      mode: 'light',
      background: {
        default: colors.grey[200],
        dark: '#161c24',
        paper: colors.common.white,
      },
      primary: {
        lighter: '#C8FACD',
        light: '#5BE584',
        main: '#007B55',
        dark: '#004b34',
        darker: '#005249',
      },
      secondary: {
        lighter: '#D6E4FF',
        light: '#84A9FF',
        main: '#3366FF',
        dark: '#1939B7',
        darker: '#091A7A',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#161c24',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        lighter: '#C8FACD',
        light: '#5BE584',
        main: '#007B55',
        dark: '#004b34',
        darker: '#005249',
      },
      secondary: {
        lighter: '#D6E4FF',
        light: '#84A9FF',
        main: '#3366FF',
        dark: '#1939B7',
        darker: '#091A7A',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  theme = responsiveFontSizes(theme);

  return theme;
};
