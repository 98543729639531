import { IconButton, Tooltip, SvgIcon } from '@mui/material';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';

function Theme(props) {
  const { settings, saveSettings } = useSettings();

  return (
    <>
      {settings.theme === THEMES.LIGHT && (
        <Tooltip title="Toggle theme">
          <IconButton
            color="inherit"
            onClick={() => saveSettings({ theme: THEMES.DARK })}
            size="large"
          >
            <SvgIcon>
              <Brightness4Icon />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      )}
      {settings.theme === THEMES.DARK && (
        <Tooltip title="Toggle theme">
          <IconButton
            color="inherit"
            onClick={() => saveSettings({ theme: THEMES.LIGHT })}
            size="large"
          >
            <SvgIcon>
              <Brightness7Icon />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default Theme;
