import {
  AppBar,
  Box,
  Container,
  Hidden,
  IconButton,
  Link,
  Toolbar,
  Tooltip,
  SvgIcon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PropTypes from 'prop-types';
import Theme from './Theme';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    height: 64,
  },
  avatarContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: '70%',
  },
  link: {
    color: 'white',
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar} disableGutters>
          <Link
            to="/"
            component={RouterLink}
            className={classes.avatarContainer}
            underline="hover"
          >
            <img
              src="/static/ava.png"
              alt="avatar"
              className={classes.avatar}
            />
          </Link>

          <Box ml={2} flexGrow={1} />
          <Hidden mdDown>
            <Tooltip title="Email me">
              <IconButton
                color="inherit"
                target="_blank"
                rel="noreferrer"
                href="mailto:lonergan.kristian@gmail.com"
                size="large"
              >
                <SvgIcon>
                  <EmailIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Kristian's LinkedIn">
              <IconButton
                component={Link}
                href="https://www.linkedin.com/in/kristianlonergan/"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
                size="large"
              >
                <SvgIcon>
                  <LinkedInIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Kristian's Github">
              <IconButton
                component={Link}
                className={classes.link}
                href="https://github.com/KristianLonergan"
                target="_blank"
                rel="noreferrer"
                size="large"
              >
                <GitHubIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
          <Theme />
          <Hidden smUp>
            <Tooltip color="inherit" title="Toggle menu">
              <IconButton onClick={onMobileNavOpen} size="large">
                <MenuRoundedIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
